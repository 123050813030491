import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
// import Header from '../components/Header';
// import SideFooter from '../components/SideFooter';
import Contact from '../components/Contact';

// import elie from '../assets/images/elie.jpg';
// import hadrien from '../assets/images/hadrien.jpg';

import { Link } from 'gatsby';

const IndexPage = () => (
    <Layout>
        <div id="wrapper">
            {/* <section className="intro">
      <h1>Formations</h1>
      <div className="content">
      <span className="image fill" data-position="center">
      <img src={heroImage} alt="" />
      </span>
      </div>
    </section> */}

            {/* <section className="intro"> */}
            <section>
                {/* <span className="image fill" data-position="center">
          <img src={heroImage} alt="" />
        </span> */}
                <div className="content">
                    <h1>Consulting</h1>
                    {/* <img src={elie} className="portrait" /> */}
                    <h2>Consulting DevOps</h2>
                    {/* <p></p> */}
                    <ul>
                        <li>Intégration d'outils <abbr rel="Intégration continue / Développement continu">CI/CD</abbr> dans vos projets d'infrastructure logicielle (Gitlab, Jenkins, Docker/Kubernetes, Ansible…)</li>
                        <li>Conseil pour la mise en place d'infrastructures DevOps (Ansible, Kubernetes…)</li>
                    </ul>
                    <h2>Consulting Sécurité informatique</h2>
                    {/* <p></p> */}
                    <ul>
                        <li>Audits de sécurité, audits de configuration, pentests en situation réaliste, simulations de <em>phishing</em> et ateliers de sensiblisation à la sécurité informatique</li>
                        <li>Conseil en renforcement de sécurité (<em>security hardening</em>) des architectures des systèmes d'information</li>
                        <li>Conception et déploiement sur mesure de solutions de type <abbr rel="Security Operation Center">SOC</abbr> basé sur des technologies open source éprouvées (suite Elastic, OSSEC, Suricata…)</li>
                        <li>Réponse à incident de type <abbr rel="Computer Emergency/Security Incident Response Team">CERT/CSIRT</abbr> et analyse forensique en cas d'attaque informatique</li>
                    </ul>

                    <p>Contactez-nous pour établir une prestation en fonction de vos besoins.</p>
                </div>
            </section>


            <Contact />


            <section>
                <div className="content">
                    <ul className="actions">
                        <p><li>
                            <Link to="/" className="button primary large">
                                Découvrir Uptime
                            </Link>
                        </li></p>
                        <p><li>
                            <a href="https://supports.uptime-formation.fr/" className="button primary large">
                                Supports de formations
                            </a>
                        </li></p>
                        <p><li>
                            <Link to="/about" className="button primary large">Qui sommes-nous ?</Link>            </li>
                        </p><p><li>
                            <Link to="/formations" className="button primary large">
                                Notre catalogue de formations
                            </Link>
                        </li></p>
                    </ul>
                </div>
            </section>

            <Footer />
        </div>
    </Layout >
);

export default IndexPage;
// Qui sommes-nous

// Elie Gavoty est actuellement formateur freelance en développement et pratiques DevOps. Après une licence d'informatique et un master recherche à l'université Paris-7 en Histoire et Philosophie des sciences, il a travaillé un an chez Sewan, opérateur télécom et hébergeur fournissant des services IT et des solutions d'infrastructure, en tant que développeur et administrateur système (DevOps). Il poursuit par ailleurs un projet de doctorat et est impliqué dans plusieurs projets d'hébergement associatif entre Paris et Strasbourg.

// Hadrien Pélissier s'intéresse à l'architecture informatique des systèmes depuis 10 ans,  et a travaillé en tant qu'ingénieur sécurité pour la sécurisation d'infrastructures informatique d'entreprises. Il assure actuellement des formations de DevOps et de sécurité. Il aime bien les projets associatifs autour des technologies, des médias et des innovations dans la gouvernance décentralisée.